/**
 * React.
 */
import * as React from "react"

/**
 * Layout.
 * Used for basic page structure.
 */
import Layout from "../components/layout"

/**
 * SEO.
 * Used for page SEO content.
 */
import Seo from "../components/seo"

/**
 * Languages.
 * Text for translated page content.
 */
import LangEN from "../languages/LangEN"
import LangFR from "../languages/LangFR"

const NotFoundPage = () => {

  /* Translate string to chosen language */
  function translate_string( str = '' ) {
    /* Set default language */
    let lang = LangEN
  
    /* Get URL so we can display page with chosen language */
    let window_url = ( typeof window !== 'undefined' ) ? window.location.href : ''
  
    /* Switch menu based on language value in url */
    if ( window_url.indexOf( "/fr/" ) > -1 ) {
      lang = LangFR
    }
  
    /* Convert string to language */
    for( var i in lang ) {
      if ( str === i ) {
        return lang[i]
      }
    }
  }

  return (
    <Layout>

      {/* SEO details */}
      <Seo title="404: Not found" />

      {/* Page title */}
      <h1>{translate_string( '404-title' )}</h1>

      {/* Page content */}
      <p>{translate_string( '404-desc' )}</p>

    </Layout>
  )

}

export default NotFoundPage
 